<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-5">
                <div class="search-box mr-2 mb-2">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Pesquise por Nome ou E-mail e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right" v-if="user.role === 'Admin'">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newGuest()">
                      <i class="mdi mdi-plus mr-1"></i> Novo Convidado
                    </button>
                  </div>
                  <div class="text-sm-right me-2">
                      <button type="button" class="btn  btn-primary btn-rounded mb-2 mr-2" @click="showFilters()">
                        <i class="mdi mdi-filter mr-1"></i> Pesquisar com filtros
                      </button>
                    </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="40%">Nome</th>
                    <th width="30%">Grupo</th>
                    <th width="10%">Status</th>
                    <th width="10%">Criação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in guests" :key="list.id" class="link">
                    <td @click="editGuest(list.id)" >
                      <h5 class="font-size-14 mb-1" style="white-space: nowrap;text-overflow: ellipsis;max-width: 275px;overflow: hidden;">{{ list.name }}</h5>
                      <p class="text-muted mb-0">{{ list.email }}</p>
                    </td>
                    <td @click="editGuest(list.id)" >
                      <span class="badge badge-soft-secondary font-size-12" style="font-weight: 400; white-space: nowrap;text-overflow: ellipsis;max-width: 400px;overflow: hidden;">
                        {{ list.group_id ? list.group.data.name : 'Sem grupo' }}
                      </span>
                    </td>
                    <td>
                      <b-dropdown
                        size="sm"
                        style="min-width:100px"
                        :variant="(parseInt(list.status)) === 99 ? 'primary' : parseInt(list.status) !== 99 ? 'success' : 'secondary'"
                      >
                        <template v-slot:button-content >
                          {{ list.status !== 99 ? 'Inscrito' : 'Convidado' }}
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <span>
                          <!-- <b-dropdown-item-button @click="toggleStatus(asking.id, 0)">Não respondida</b-dropdown-item-button> -->
                          <b-dropdown-item-button disabled>Convidado</b-dropdown-item-button>
                          <b-dropdown-item-button @click="toggleStatus(list.id, 0)">Mover para Inscrito</b-dropdown-item-button>
                        </span>
                      </b-dropdown>
                    </td>
                    <td @click="editGuest(list.id)" style="font-size: 12px !important;">
                      <span class="badge font-size-12" style="min-width:100px" :class="{
                        'badge-soft-primary': `${parseInt(list.type_creator)}` === '1',
                        'badge-soft-warning': `${parseInt(list.type_creator)}` === '2',
                        'badge-soft-secondary': `${parseInt(list.type_creator)}` === '3',
                      }">{{ typeCreator[list.type_creator] }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idGuest="idGuest" ref="formGuest" @created="updateList"></modal-form>
    <modal-filter ref="modalFilter" @guestFiltered="getReport"></modal-filter>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import modalFilter from '@/components/modals/filters/GuestFilter'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ModalForm,
    modalFilter
  },
  name: 'Convidados',
  page: {
    title: 'Convidados',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      search: '',
      modalForm: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      typeCreator: ['', 'MANUAL', 'IMPORTADO', 'FORM EXTERNO'],
      idGuest: null,
      perPage: 20,
      submitted: false,
      // title: 'Lista de Convidados',
      items: [{
        text: 'Convidados'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('guest', ['guests', 'pagination']),
    rows () {
      return this.pagination.total
    },
    title () {
      return 'Lista de Convidados - ' + this.rows
    }
  },
  methods: {
    ...mapActions('guest', {
      getAll: 'getAll',
      changeStatus: 'changeStatus'
    }),
    showFilters () {
      this.$refs.modalFilter.open()
    },
    newGuest () {
      this.$refs.formGuest.open('')
      this.modalForm = true
    },
    editGuest (id) {
      this.$refs.formGuest.open(id)
      this.modalForm = true
      this.idGuest = id
    },
    toggleStatus (participantId, status) {
      if (this.user.role === 'Admin' || this.user.role === 'Master') {
        Swal.fire({
          title: 'Você tem certeza?',
          text: `Você tem certeza que deseja inscrever esse convidado manualmente?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#388741',
          cancelButtonColor: '#4a4848',
          cancelButtonText: 'CANCELAR',
          confirmButtonText: 'MOVER PARA INSCRITOS'
        }).then(result => {
          if (result.value) {
            try {
              this.changeStatus({ id: participantId, status }).then((res) => {
                successMessage('Sucesso: Status atualizado.', 2500)
              }).then(() => {
                this.updateList()
              }).catch((err) => {
                errorMessage('Alerta: ' + err.message)
              })
            } catch (err) {
              console.log(err)
            }
          }
        })
        return
      }
      errorMessage('Atenção: Você não tem permissão para realizar essa ação!', 2500)
    },
    deleteUser (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteParticipant(id).then((res) => {
              successMessage('Sucesso: Participante excluído com sucesso.', 2500)
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        group_id: filter.group_id || null,
        search: this.search || null,
        type_creator: filter.type_creator || null,
        limit: this.perPage,
        page: this.currentPage,
        include: 'group',
        status: filter.status || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
