<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveCampaign()" autocomplete="off">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="name">Nome da campanha</label>
                <input
                  id="name"
                  v-model="campaign.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.campaign.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.campaign.name.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="type_contact">Destinatários</label>
                <select
                  id="type_contact"
                  v-model="campaign.type_contact"
                  name="type_contact"
                  type="text"
                  class="custom-select"
                  :class="{
                    'is-invalid': submitted && $v.campaign.type_contact.$error
                  }"
                >
                  <option></option>
                  <option value="guests">Convidados</option>
                  <option value="registered">Inscritos</option>
                </select>
                <div
                  v-if="submitted && !$v.campaign.type_contact.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div :class="{ 'col-sm-6': campaign.type_filter, 'col-sm-12': !campaign.type_filter }">
              <div class="form-group">
                <label for="type_filter">Filtrar</label>
                <select
                  class="custom-select"
                  name="type_filter"
                  v-model="campaign.type_filter"
                  @change="setFilterCampaign()"
                >
                  <option value=""></option>
                  <option value="group">Grupo</option>
                  <option value="tags">Tags</option>
                  <option value="status">Status</option>
                  <option value="checkin">Check-in</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group" v-if="campaign.type_filter === 'group'">
                <label for="groups">Grups</label>
                <multiselect tag-placeholder="Selecione uma ou mais grupos" placeholder="Selecione uma ou mais grupos" v-model="campaign.groups" label="name" track-by="id" :options="groups" :multiple="true"></multiselect>
              </div>
              <div class="form-group" v-if="campaign.type_filter === 'tags'">
                <label for="type">Tags</label>
                <multiselect tag-placeholder="Selecione ma ou mais tags" placeholder="Selecione uma ou mais tags" v-model="campaign.tags" label="name" track-by="id" :options="tags" :multiple="true"></multiselect>
              </div>
              <div class="form-group" v-if="campaign.type_filter === 'status'">
                <label for="type">Status</label>
                <multiselect tag-placeholder="Selecione uma ou mais status" placeholder="Selecione uma ou mais status" v-model="campaign.status" :options="statusSelect" track-by="id" label="name" :multiple="true"></multiselect>
              </div>
              <div class="form-group" v-if="campaign.type_filter === 'checkin'">
                <label for="type">Check-in</label>
                <multiselect tag-placeholder="Selecione uma opção" placeholder="Selecione uma opção" v-model="campaign.checkin" :options="checklist" track-by="id" label="name" :multiple="true"></multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="template_id">Template</label>
                <select
                  class="custom-select"
                  name="template_id"
                  v-model="campaign.template_id"
                  :class="{
                    'is-invalid': submitted && $v.campaign.template_id.$error
                  }"
                >
                  <option></option>
                  <option v-for="(t,i) in templates" :key=i :value="t.id">{{ t.namespace }}</option>
                </select>
                <div
                  v-if="submitted && !$v.campaign.template_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
                <button type="button" class="btn btn-outline-dark btn-sm mt-2" v-if="campaign.template_id" @click="showExampleTemplate">
                  <i class="bx bx-show link" ></i> Ver exemplo do template
                </button>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 1rem">
            <div class="col-sm-3">
              <div style="display:flex; align-items: start; gap: 5px;margin-top: 0.55rem">
                <span style="font-weight: 500;margin-top:2px">Agendar disparo</span>
                <b-form-checkbox v-model="campaign.has_scheduled_at" switch size="lg"></b-form-checkbox>
              </div>
            </div>
            <div class="col-sm-4" v-if="campaign.has_scheduled_at">
              <!-- <label for="scheduled_at">Data do Agendamento</label> -->
              <date-picker
                v-model="campaign.scheduled_at"
                type="datetime"
                :first-day-of-week="1"
                lang="pt-br"
                format="DD/MM/YYYY HH:mm"
                name="scheduled_at"
              >
              </date-picker>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveCampaign()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pt-br'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Loading,
    DatePicker,
    Multiselect
  },
  props: {
    idcampaign: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Nova Campanha',
      loading: true,
      fullPage: true,
      submitted: false,
      checklist: [
        { id: 1, name: 'SIM' }
      ],
      campaign: {
        groups: [],
        tags: [],
        status: [],
        checkin: []
      },
      modal: false
    }
  },
  validations: {
    campaign: {
      name: { required },
      type_contact: { required },
      template_id: { required }
    }
  },
  methods: {
    ...mapActions('settings', { GetStatus: 'GetStatusSelect' }),
    ...mapActions('campaign', {
      getCampaign: 'show',
      postData: 'save'
    }),
    ...mapActions('template', {
      getTemplatesAll: 'getAll'
    }),
    ...mapActions('tag', {
      getAll: 'getAll'
    }),
    ...mapActions('group', {
      getAllGroups: 'getAll'
    }),
    showExampleTemplate () {
      const template = this.templates.filter((t) => t.id === this.campaign.template_id)[0]
      if (template) {
        this.$emit('exampleTemplate', template.components)
      }
    },
    setFilterCampaign () {
      this.campaign.groups = []
      this.campaign.status = []
      this.campaign.tags = []
      this.campaign.checkin = []
      console.log('sestou aqui')
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Campanha'
        this.getCampaign(id).then((data) => {
          this.campaign = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    getTags (filter) {
      let params = {
        search: this.search || null,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    getTemplates () {
      let params = {
        search: this.search || null,
        status: 1,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getTemplatesAll(this.params).then((res) => {
      }).catch(error => {
        console.log(error)
      })
    },
    getGroups () {
      let params = {
        search: this.search || null,
        status: 1,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAllGroups(this.params).then((res) => {
      }).catch(error => {
        console.log(error)
      })
    },
    open (id = null) {
      this.getTags()
      this.getTemplates()
      this.getGroups()
      this.GetStatus()
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveCampaign () {
      this.submitted = true
      this.$v.campaign.$touch()
      if (this.$v.campaign.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      if (this.campaign.has_scheduled_at) {
        this.campaign.scheduled_at = this.$moment(this.campaign.scheduled_at).format('YYYY-MM-DD HH:mm:ss')
      }
      await this.postData(this.campaign).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.campaign = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('settings', ['statusSelect']),
    ...mapState('campaign', { data: 'campaign' }),
    ...mapState('template', ['templates']),
    ...mapState('tag', ['tags']),
    ...mapState('group', ['groups'])
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
