<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveTag()" autocomplete="off">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="column_name">Coluna</label>
                <select
                  class="custom-select"
                  name="column_name"
                  v-model="mapping.column_name"
                >
                  <option value=""></option>
                  <option v-for="(g,idx) in columns" :key="idx" :value="g">{{ g }}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="form-group">
                <label for="friendly_name">Nome amigável</label>
                <input
                  id="friendly_name"
                  v-model="mapping.friendly_name"
                  name="friendly_name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.mapping.friendly_name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.mapping.friendly_name.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveTag()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import service from './services'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idTag: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Nova Coluna',
      loading: true,
      fullPage: true,
      submitted: false,
      columns: [],
      mapping: {},
      modal: false
    }
  },
  validations: {
    mapping: {
      friendly_name: { required },
      column_name: { required }
    }
  },
  methods: {
    ...mapActions('reports', {
      getColumn: 'show',
      postData: 'save'
    }),
    getColumns () {
      service.listColumns().then(({ data }) => {
        this.columns = data.data
      })
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Coluna'
        this.getColumn(id).then((data) => {
          this.mapping = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      this.getColumns()
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveTag () {
      this.submitted = true
      this.$v.mapping.$touch()
      if (this.$v.mapping.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.mapping).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.mapping = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('reports', { data: 'report' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
