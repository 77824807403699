<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <div class="search-box mr-2 mb-2 d-inline-block ">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right" v-if="user.role === 'Admin'">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newColumn()">
                      <i class="mdi mdi-plus mr-1"></i> Nova Coluna
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="20px">Ordem</th>
                    <th width="40%">Modulo</th>
                    <th width="30%">Nome amigável</th>
                    <th width="20%">Coluna</th>
                    <th width="20px">#</th>
                  </tr>
                </thead>
                <draggable
                  v-model="reports"
                  :element="'tbody'"
                  :options="{animation:200, handle:'.mover'}"
                  @change="updateSequence"
                >
                  <tr v-for="list in reports" :key="list.id">
                    <td class="mover">
                      <span class="badge font-size-13 badge-soft-primary p-1 badge-num">{{ list.sequence }}</span>
                    </td>
                    <td class="link" @click="editColumn(list.id)">
                      <span class="badge font-size-14 badge-soft-primary" style="min-width:100px">{{ list.table_name }}</span>
                    </td>
                    <td class="link" @click="editColumn(list.id)">{{ list.friendly_name }}</td>
                    <td class="link" @click="editColumn(list.id)">{{ list.column_name }}</td>
                    <td><button v-if="user.role === 'Admin'" type="button" @click="deleteTag(list)" class="link btn btn-outline-danger btn-sm"><i class="bx bx-trash"></i></button></td>
                  </tr>
                </draggable>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idColumn="idColumn" ref="formMapping" @created="updateList"></modal-form>
  </Layout>
</template>
<script>
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState, mapGetters } from 'vuex'
import service from './services'

export default {
  components: {
    draggable,
    Layout,
    PageHeader,
    ModalForm
  },
  name: 'Configuração Relatórios',
  page: {
    title: 'Configuração Relatórios',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      search: '',
      timeout: null,
      modalForm: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idColumn: null,
      setting: {},
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      submitted: false,
      title: 'Configuração Relatórios',
      items: [{
        text: 'Configuração Relatórios'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false

    this.GetSetting().then(() => {
      this.setting = this.data
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('settingEvent', { data: 'setting' }),
    ...mapState('reports', ['pagination']),
    ...mapGetters('reports', { data: 'REPORTS' }),
    rows () {
      return this.pagination.total
    },
    reports: {
      get () {
        return this.data
      },
      set (value) {
        let loader = this.$loading.show()
        this.updateSequence({ itens: value }).then(res => {
          loader.hide()
          successMessage('Sucesso: Ordem atualizada.', 2500)
        })
      }
    }
  },
  methods: {
    ...mapActions('settingEvent', {
      GetSetting: 'GetSetting'
    }),
    ...mapActions('reports', {
      getAll: 'getAll',
      delete: 'deleteR',
      updateSequence: 'orderList'
    }),
    changeCheckin () {
      service.toogleSetting({ has_checkout: this.setting.has_checkout })
        .then((data) => {
          successMessage('Sucesso: ' + data.data.message, 2500)
        }).catch((error) => {
          this.setting.has_checkout = false
          errorMessage('Atenção: ' + error.message)
        })
    },
    newColumn () {
      this.$refs.formMapping.open('')
      this.modalForm = true
    },
    editColumn (id) {
      if (this.user.role === 'Admin') {
        this.$refs.formMapping.open(id)
        this.modalForm = true
        this.idColumn = id
      }
    },
    deleteTag (tag) {
      Swal.fire({
        title: 'Você tem certeza ?',
        html: `Será excluido a tag: <b>${tag.name}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.delete(tag.id)
            successMessage('Sucesso: TAG excluída com sucesso.', 2500)
            this.updateList()
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
